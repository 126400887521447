import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

import FilmSection from "../components/filmsection"

const Film = () => {
  return (
    <Layout>
      <Head title="Film" />
      <FilmSection />
    </Layout>
  )
}

export default Film
