import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as blogStyles from "./blog.module.scss"
import "../styles/index.scss"

const FilmSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVideoEmbeds(sort: { fields: date, order: DESC }) {
        edges {
          node {
            embedURL
          }
        }
      }
    }
  `)

  return (
    <div className={`${blogStyles.blogDiv}`}>
      {data.allContentfulVideoEmbeds != null &&
        data.allContentfulVideoEmbeds.edges.map(banana => {
          return (
            <div className={`${blogStyles.blogPost}`}>
              <div dangerouslySetInnerHTML={{ __html: banana.node.embedURL }} />
            </div>
          )
        })}
    </div>
  )
}

export default FilmSection
